import Link from 'next/link'
import classNames from 'classnames/bind'

import ButtonLink from 'components/ButtonLink'
import UserContentImage from 'components/UserContentImage'

import usePromotionBanner from './usePromotionBanner'

import { PromotionsPreviewMode } from 'modules/seller-hub/promotions/components/PromotionConfigureForm/PromotionConfigureForm'
import { GetCurrentPromotions_currentPromotions_slots_nodes } from '../../pages/CatalogListPage/graphql/__generated__/GetCurrentPromotions'
import { GetConfigurePromotionSlotDetails_currentSeller_promotionSlots_nodes } from 'modules/seller-hub/promotions/pages/PromotionConfigurePage/graphql/__generated__/GetConfigurePromotionSlotDetails'

import styles from './PromotionBanner.module.css'
const cx = classNames.bind(styles)

type PromotionBannerProps = {
  promotionSlot?:
    | GetConfigurePromotionSlotDetails_currentSeller_promotionSlots_nodes
    | GetCurrentPromotions_currentPromotions_slots_nodes
  promotionsPreviewMode?: PromotionsPreviewMode
  className?: string
  isHomepageBanner?: boolean
  isButtonLocked?: boolean
}

const PromotionBanner = ({
  promotionSlot,
  promotionsPreviewMode,
  className,
  isHomepageBanner = false,
  isButtonLocked = false
}: PromotionBannerProps) => {
  const { handlePromotionClick } = usePromotionBanner()

  if (!promotionSlot) return null

  const content = promotionSlot.content
  const catalog = promotionSlot.content?.catalog
  const sellerSlug = catalog?.seller?.slug
  const catalogSlug = catalog?.slug
  const isMobilePreview = promotionsPreviewMode === 'mobile'
  const catalogPath = `${sellerSlug}/${catalogSlug}`
  const catalogUrl = `${document.location.origin}/${catalogPath}`

  return (
    <div
      className={cx(className, styles.container, { isHomepageBanner, isMobilePreview })}
      onClick={() => (!promotionsPreviewMode ? handlePromotionClick(promotionSlot.id, catalogUrl) : null)}>
      <Link href={`/${catalogPath}`}>
        <a>
          <div className={styles.cover}>
            <UserContentImage className={styles.coverImage} src={catalog?.photoMediumUrl} alt="" />
          </div>
        </a>
      </Link>
      <div className={styles.text}>
        <div className={styles.spotlight}>
          <span className={styles.spotlightDepartment}>
            {(promotionSlot as GetCurrentPromotions_currentPromotions_slots_nodes)?.promotion?.department?.name ?? ''}{' '}
            Spotlight
          </span>
          <span className={styles.spotlightSellerName}>{catalog?.seller?.displayName}</span>
        </div>

        <div className={styles.content}>
          {content && (
            <>
              <span className={styles.headline}>{content.headline}</span>
              <span className={styles.bodyText}>{content.bodyText}</span>
            </>
          )}

          <ButtonLink
            className={cx({ isButtonLocked })}
            href={`/${catalogPath}`}
            label={`Shop ${catalog?.seller?.displayName}`}
            icon="arrow-right"
            iconPosition="right"
            kind="dark"
          />
        </div>
      </div>
    </div>
  )
}

export default PromotionBanner
